import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    title: "需求终止",
    onOk: _ctx.handleOk
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        model: _ctx.formState,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "需求产品名称" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.demandProduct,
                placeholder: "需求产品名称",
                disabled: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "应用设备名称" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.deviceName,
                placeholder: "应用设备名称",
                disabled: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "终止原因" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                value: _ctx.formState.abrogationReason,
                onChange: _ctx.change,
                placeholder: "该原因用于回复客户，请谨慎填写。",
                rows: 4
              }, null, 8, ["value", "onChange"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["visible", "onOk"]))
}