
import { defineComponent, inject, ref } from 'vue'
import demandApi from '@/axios/api/demand'
import { message } from 'ant-design-vue'
export default defineComponent({
  props: {
    formState: {
      type: Object,
      default: () => {
        return {
          id: '',
          demandProduct: '',
          deviceName: '',
          abrogationReason: '',
        }
      },
    },
  },
  emits: ['changeReason', 'onSuccess'],
  setup(props, { emit }) {
    const show = ref(inject('terminationShow'))
    const handleOk = () => {
      console.log('确认')
      demandApi
        .Termination(props.formState)
        .then(() => {
          message.success('终止成功')
          show.value = false
          emit('onSuccess')
        })
        .catch((err) => {
          message.error(err)
        })
    }
    const optionList = [
      { label: '重要紧急', value: 0 },
      { label: '重要不紧急', value: 1 },
      { label: '紧急不重要', value: 2 },
      { label: '不重要不紧急', value: 3 },
    ]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const change = (e: any) => {
      console.log(e.target.value)

      emit('changeReason', e.target.value)
    }
    return {
      show,
      handleOk,
      change,
      optionList,
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    }
  },
})
